import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import FeaturedTalents from "../sections/landing1/FeaturedTalents";
import Content2 from "../sections/landing1/Content2";
import FeaturedJobs from "../sections/landing1/FeaturedJobs";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          bgClass: "dynamic-sticky-bg",
        }}
      >
        <Hero />
        <FeaturedTalents />
        <FeaturedJobs />
        <Content2 />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
