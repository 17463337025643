import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";

import { DataContext } from "../../context/DataContext";
import PageWrapper from "../../components/PageWrapper";
import imageNotFound from "../../assets/image/image-not-found.jpg";
import fireIcon from "../../assets/image/svg/icon-fire-rounded.svg";

const FeaturedJobs = () => {
  const [loading, setLoading] = useState(true);
  const { jobs, getAllJobsList } = useContext(DataContext);

  const fetchJobs = async () => {
    await getAllJobsList();
    setLoading(false);
  };

  useEffect(() => {
    fetchJobs();
    return () => setLoading(false);
  }, []);

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-19 pt-lg-22 pb-7 pb-lg-23">
          <div className="container">
            <div className="row align-items-center pb-14">
              <div className="col-12 col-xl-6 col-lg-6">
                <div className="text-center text-lg-left mb-13 mb-lg-0">
                  <h3 className="font-size-8 font-weight-bold">
                    Featured Jobs
                  </h3>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6">
                <div className="text-center text-lg-right">
                  <Link
                    to="/search-list"
                    className="btn btn-outline-mercury text-black-2 text-uppercase"
                  >
                    Explore All
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="mb-5">
                  {loading ? (
                    <div className="row">
                      {[1, 2, 3].map((index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-12 mb-9"
                        >
                          <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3">
                            <div className="media align-items-center">
                              <div className="square-52 mr-8 rounded">
                                <Skeleton width={52} height={52} />
                              </div>
                              <div>
                                <Skeleton width={150} height={12} />
                                <h3 className="font-size-6 mb-0">
                                  <Skeleton width={250} height={26} />
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex pt-4 align-items-center">
                              <ul className="list-unstyled mb-1 d-flex flex-wrap">
                                <li>
                                  <Skeleton
                                    width={100}
                                    height={26}
                                    containerClassName="mr-4"
                                  />
                                </li>
                                <li>
                                  <Skeleton
                                    width={100}
                                    height={26}
                                    containerClassName="mr-4"
                                  />
                                </li>
                                <li>
                                  <Skeleton
                                    width={100}
                                    height={26}
                                    containerClassName="mr-4"
                                  />
                                </li>
                              </ul>
                            </div>
                            <div className="d-flex pt-4 align-items-center">
                              <ul className="list-unstyled mb-1 d-flex flex-wrap">
                                <li>
                                  <Skeleton
                                    width={100}
                                    height={32}
                                    containerClassName="mr-4"
                                  />
                                </li>
                                <li>
                                  <Skeleton width={100} height={32} />
                                </li>
                              </ul>
                              <div className="ml-auto px-0">
                                <Skeleton width={32} height={32} className="rounded-circle" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      {jobs
                        .filter(
                          (job) =>
                            ["pending", "read"].includes(job.status) &&
                            job?.featured
                        )
                        .slice(0, 6)
                        .map((job, index) => (
                          <div
                            key={index}
                            className="col-lg-4 col-md-6 col-sm-12 mb-9"
                          >
                            <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 border border-primary">
                              <div className="media align-items-center">
                                <div className="square-52 mr-8 rounded">
                                  <Link
                                    to={`/job-details/${job.id}`}
                                    state={{ id: job.id }}
                                  >
                                    {job?.logo ? (
                                      <img
                                        src={job?.logo}
                                        alt={`${job?.company} logo`}
                                        height={52}
                                      />
                                    ) : (
                                      <img
                                        src={imageNotFound}
                                        alt="Not found"
                                        height={52}
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div>
                                  <span className="font-size-3 text-default-color line-height-2">
                                    {job?.company}
                                  </span>
                                  <h3 className="font-size-6 mb-0 height-69-px">
                                    <Link
                                      to={`/job-details/${job.id}`}
                                      state={{ id: job.id }}
                                      className="heading-default-color font-weight-semibold"
                                    >
                                      {job?.position}
                                    </Link>
                                  </h3>
                                </div>
                              </div>
                              <div className="pt-7">
                                <ul className="d-flex list-unstyled mr-n3 flex-wrap justify-content-left max-height-px-32">
                                  {job?.skills &&
                                    job.skills
                                      .slice(0, 3)
                                      .map((skill, index) => (
                                        <li key={index}>
                                          <Link
                                            to="/#"
                                            onClick={(e) => e.preventDefault()}
                                            className="bg-polar text-black-2  mr-4 px-3 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                          >
                                            {skill}
                                          </Link>
                                        </li>
                                      ))}
                                </ul>
                              </div>

                              <div className="d-flex align-items-start">
                                <ul className="list-unstyled d-flex flex-wrap h-84">
                                  <li>
                                    <span
                                      to="/#"
                                      className="bg-regent-opacity-15 text-denim font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4"
                                    >
                                      <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                      {job?.location}
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      to="/#"
                                      className="bg-regent-opacity-15 text-orange font-size-3 rounded-3 min-width-px-100 px-3 flex-all-center mr-6 h-px-33 mt-4"
                                    >
                                      <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                      {job?.jobtype}
                                    </span>
                                  </li>
                                </ul>
                                {job?.featured ? (
                                  <div className="ml-auto px-0 image mt-5">
                                    <img src={fireIcon} alt="Featured job" />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default FeaturedJobs;
