const API_URL =
  "https://api.coingecko.com/api/v3/simple/price?ids=near&vs_currencies=usd";

export const getExchangeRate = async () => {
  try {
    const response = await fetch(API_URL);
    const data = await response.json();
    return data.near.usd;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};
