import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import imageNotFound from "../../assets/image/image-not-found.jpg";
import { DataContext } from "../../context/DataContext";
import { AuthContext } from "../../context/AuthContext";
import { getExchangeRate } from "../../services/tokenConverter";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const FeaturedTalents = () => {
  const [loading, setLoading] = useState(true);
  const [exchangeRate, setExchangeRate] = useState(0);
  const { talents, getAllTalentList } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  const fetchFreelancers = async () => {
    await fetchExchangeRate();
    await getAllTalentList();
    setLoading(false);
  };

  const fetchExchangeRate = async () => {
    const rate = await getExchangeRate();
    setExchangeRate(rate);
  };

  useEffect(() => {
    fetchFreelancers();
    return () => setLoading(false);
  }, []);

  return (
    <>
      <div className="pt-11 pt-lg-27 pb-7 pb-lg-26 bg-black-2 dark-mode-texts">
        <div className="container">
          <div className="row align-items-center pb-14">
            {/* <!-- Section Title --> */}
            <div className="col-12 col-xl-6 col-lg-6">
              <div className="text-center text-lg-left mb-13 mb-lg-0">
                <h3 className="font-size-8 font-weight-bold">
                  Featured Talents
                </h3>
              </div>
            </div>
            {/* <!-- Section Button --> */}
            <div className="col-12 col-xl-6 col-lg-6">
              <div className="text-center text-lg-right">
                <Link
                  to="/talent-list"
                  className="btn btn-outline-white text-uppercase"
                >
                  Explore All
                </Link>
              </div>
            </div>
            {/* <!-- Section Button End --> */}
          </div>

          <div className="mt-12">
            {loading ? (
              <div className="row px-sm-20">
                <div className="col-xs-12 col-sm-4 col-md-6 col-lg-4">
                  <div className="bg-white px-6 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                    <div className="d-flex justify-content-end mb-4">
                      <Skeleton width={80} height={26} />
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                      <Skeleton
                        width={120}
                        height={120}
                        className="rounded-circle"
                      />
                    </div>
                    <h2 className="mt-n1 text-center">
                      <Skeleton width={200} height={36} />
                    </h2>
                    <div className="text-center mt-n4 mb-1">
                      <Skeleton width={150} height={26} />
                    </div>
                    <ul className="list-unstyled mb-1 card-tag-list justify-content-center">
                      <li>
                        <Skeleton width={80} height={32} />
                      </li>
                      <li>
                        <Skeleton width={100} height={32} />
                      </li>
                    </ul>
                    <div className="d-flex justify-content-center">
                      <Skeleton height={44} containerClassName="w-75" />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-6 col-lg-4">
                  <div className="bg-white px-6 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                    <div className="d-flex justify-content-end mb-4">
                      <Skeleton width={80} height={26} />
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                      <Skeleton
                        width={120}
                        height={120}
                        className="rounded-circle"
                      />
                    </div>
                    <h2 className="mt-n1 text-center">
                      <Skeleton width={200} height={36} />
                    </h2>
                    <div className="text-center mt-n4 mb-1">
                      <Skeleton width={150} height={26} />
                    </div>
                    <ul className="list-unstyled mb-1 card-tag-list justify-content-center">
                      <li>
                        <Skeleton width={80} height={32} />
                      </li>
                      <li>
                        <Skeleton width={100} height={32} />
                      </li>
                    </ul>
                    <div className="d-flex justify-content-center">
                      <Skeleton height={44} containerClassName="w-75" />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-6 col-lg-4">
                  <div className="bg-white px-6 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                    <div className="d-flex justify-content-end mb-4">
                      <Skeleton width={80} height={26} />
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                      <Skeleton
                        width={120}
                        height={120}
                        className="rounded-circle"
                      />
                    </div>
                    <h2 className="mt-n1 text-center">
                      <Skeleton width={200} height={36} />
                    </h2>
                    <div className="text-center mt-n4 mb-1">
                      <Skeleton width={150} height={26} />
                    </div>
                    <ul className="list-unstyled mb-1 card-tag-list justify-content-center">
                      <li>
                        <Skeleton width={80} height={32} />
                      </li>
                      <li>
                        <Skeleton width={100} height={32} />
                      </li>
                    </ul>
                    <div className="d-flex justify-content-center">
                      <Skeleton height={44} containerClassName="w-75" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row px-sm-20">
                <Swiper
                  centeredSlides={false}
                  autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                  }}
                  slidesPerView={3}
                  spaceBetween={15}
                  slidesPerGroup={4}
                  loop={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper ml-sm-4 px-4"
                >
                  {talents
                    .filter((talent) => talent?.featured)
                    .map((talent, index) => (
                      <SwiperSlide key={index} className="w-auto">
                        <div className="col-12">
                          <div className="bg-white px-6 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments min-width-px-320 border border-primary">
                            <div className="d-flex justify-content-end mb-4">
                              <span className="bg-regent-opacity-15 text-gray font-weight-bold font-size-3 rounded-3 px-4 py-2">
                                {`${
                                  talent.currency === "USDT"
                                    ? talent.rate
                                    : Math.floor(
                                        (talent.rate * exchangeRate).toFixed(2)
                                      )
                                } USDT/h`}
                              </span>
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                              <Link
                                to={`/candidate-profile/${talent.account_id}`}
                                state={{ id: talent.account_id }}
                              >
                                {talent?.imageUrl ? (
                                  <img
                                    src={talent?.imageUrl}
                                    alt={`${talent.firstname} ${talent.firstname} Devshop profile`}
                                    height={120}
                                    className="rounded-circle"
                                  />
                                ) : (
                                  <img
                                    src={imageNotFound}
                                    alt="Not found"
                                    height={120}
                                    className="rounded-circle"
                                  />
                                )}
                              </Link>
                            </div>
                            <Link
                              to="/#"
                              onClick={(e) => e.preventDefault()}
                              className="font-size-3 d-block mb-0 text-gray text-center"
                            >
                              {talent?.title}
                            </Link>
                            <h2 className="mt-n4 text-center">
                              <Link
                                to="/#"
                                onClick={(e) => e.preventDefault()}
                                className="font-size-5 text-black-2 font-weight-bold"
                              >
                                {`${
                                  talent?.firstname
                                } ${talent?.lastname?.charAt(0)}`}
                              </Link>
                            </h2>
                            <div>
                              <ul className="d-flex list-unstyled mr-n3 flex-wrap justify-content-center max-width-px-294 max-height-px-32">
                                {talent?.skills &&
                                  talent.skills
                                    .slice(0, 3)
                                    .map((skill, index) => (
                                      <li key={index}>
                                        <Link
                                          to="/#"
                                          onClick={(e) => e.preventDefault()}
                                          className="bg-polar text-black-2  mr-4 px-3 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                        >
                                          {skill}
                                        </Link>
                                      </li>
                                    ))}
                              </ul>
                            </div>
                            <ul className="list-unstyled card-tag-list justify-content-center mb-2">
                              <li>
                                <Link
                                  to="/#"
                                  onClick={(e) => e.preventDefault()}
                                  className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                                >
                                  <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                  {talent?.city}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/#"
                                  onClick={(e) => e.preventDefault()}
                                  className="bg-regent-opacity-15 text-orange font-size-3 rounded-3"
                                >
                                  <i className="fa fa-star mr-2 font-weight-bold"></i>{" "}
                                  {`${talent?.rating || 0}/5`}
                                </Link>
                              </li>
                            </ul>
                            {auth?.talent || !auth ? null : (
                              <div className="card-btn-group justify-content-center">
                                <Link
                                  to={`/candidate-profile/${talent.account_id}`}
                                  state={{ id: talent.account_id }}
                                  className="btn btn-green text-uppercase btn-small rounded-3 w-75"
                                >
                                  Hire Me
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedTalents;
